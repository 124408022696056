import Grid from "@material-ui/core/Grid"
import { graphql} from "gatsby"
import Img from "gatsby-image"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SocialShare from "../components/socialShare"
import { Wrapper, Title, WineCard, TopSection, ContentSection } from "../components/styles/styles"
import SuggestionsBox from "../components/suggestion_v2"

const WineTemplate = props => {
  const { data } = props
  return (
    <Layout>
      <SEO title={data.markdownRemark.frontmatter.title} />

      <Grid container spacing={1}>
        <TopSection item lg={4} xs={12} sm={6}>
          {data.markdownRemark.frontmatter.featuredImage?.childImageSharp
            ?.fixed && (
            <Img
              style={{ width: "100%", height: "600px" }}
              fixed={
                data.markdownRemark.frontmatter.featuredImage?.childImageSharp
                  ?.fixed
              }
            />
          )}
        </TopSection>
        <TopSection item lg={8} xs={12} sm={6}>
          <Title
            size="36px"
            dangerouslySetInnerHTML={{
              __html: data.markdownRemark.frontmatter.title,
            }}
          />

          <SocialShare url={props.location.href} />
          <WineCard>
            <Grid item lg={3} xs={12} sm={12}>
              <div className="wine_fields">
                <h5>Style:</h5>
                <h5>Blend:</h5>
                <h5>Vintage:</h5>
                <h5>Appellation:</h5>
                <h5>ABV:</h5>
                {/*<h5>Price:</h5>*/}
              </div>
            </Grid>
            <Grid item lg={6} xs={12} sm={12}>
              <div className="wine_details">
                <h5>{data.markdownRemark.frontmatter.style}</h5>
                <h5>{data.markdownRemark.frontmatter.blend}</h5>
                <h5>{data.markdownRemark.frontmatter.vintage}</h5>
                <h5>{data.markdownRemark.frontmatter.appellation}</h5>
                <h5>{data.markdownRemark.frontmatter.abv}</h5>
                {/*<h5>{data.markdownRemark.frontmatter.wine_fields.price}</h5>*/}
              </div>
            </Grid>
          </WineCard>
        </TopSection>

        <Grid item lg={8} xs={12} sm={12}>
          <Wrapper>
            <Title size="36px">The Full Story</Title>

            <ContentSection
              size="18px"
              dangerouslySetInnerHTML={{
                __html: data.markdownRemark.frontmatter.content,
              }}
            />
          </Wrapper>
        </Grid>
        <Grid item lg={4} xs={12} sm={12}>
          <SuggestionsBox data={data.markdownRemark.frontmatter} />
        </Grid>
      </Grid>
    </Layout>
  )
}
export default WineTemplate

export const WineTemplateQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      fields {
        slug
      }
      frontmatter {
        title
        style
        vintage
        price
        blend
        appellation
        abv
        date
        content
        suggested_articles
        suggested_recipes
        suggested_wines
        featuredImage {
          childImageSharp {
            fixed(quality: 100, height: 600) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
  }
`

